export let questions = [
  {
    id: 1,
    title: "Qual nível de qualidade você está procurando?",
    answers: [
      "Qualidade excelente",
      "Boa relação de qualidade e preço",
      "Preciso de algo básico",
    ],
    prices: [10426, 8160, 6686],
    images: ["answer-1-1", "answer-1-2", "answer-1-3"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 2,
    title: "Qual tipo de aplicativo você precisa?",
    answers: ["Android", "iOS", "Android e iOS"],
    prices: [8160, 8160, 10426],
    images: ["answer-2-1", "answer-2-2", "answer-2-4"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 3,
    title: "Qual tipo de interface você deseja que o seu aplicativo tenha?",
    answers: [
      "Personalizada",
      "Simples",
      "Igual ao site que possuo",
      "Sem interface",
    ],
    prices: [10426, 8160, 6686, 0],
    images: ["answer-3-2", "answer-3-1", "answer-3-3", "answer-3-4"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 4,
    title: "Qual retorno financeiro você deseja ter?",
    answers: [
      "Compras dentro do aplicativo",
      "Aplicativo gratuito com publicidade",
      "Aplicativo pago",
      "Não sei",
    ],
    prices: [10426, 8160, 4420, 6686],
    images: ["answer-4-3", "answer-4-1", "answer-4-2", "answer-4-4"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 5,
    title: "O seu aplicativo precisa de um sistema de login?",
    answers: [
      "Sim, com redes sociais e email",
      "Sim, apenas com email",
      "Não é necessário login",
      "Não sei",
    ],
    prices: [10426, 8160, 0, 6686],
    images: ["answer-5-1", "answer-5-2", "answer-5-3", "answer-5-4"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 6,
    title: "Seu aplicativo deve ser integrado (API) à um website?",
    answers: ["Sim", "Não", "Não sei"],
    prices: [10426, 0, 8160],
    images: ["answer-6-1", "answer-6-2", "answer-6-3"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 7,
    title: "Os usuários devem ter seu perfil próprio?",
    answers: ["Sim", "Não", "Não sei"],
    prices: [10426, 0, 8160],
    images: ["answer-7-1", "answer-7-2", "answer-7-3"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 8,
    title: "Seu aplicativo necessita de um painel administrativo?",
    answers: ["Sim", "Não", "Não sei"],
    prices: [10426, 0, 8160],
    images: ["answer-8-1", "answer-8-2", "answer-8-3"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 9,
    title: "Quantas línguas você deseja que seu aplicativo tenha?",
    answers: ["Única língua", "Bilíngue", "Multilíngue"],
    prices: [6686, 8160, 10426],
    images: ["answer-9-1", "answer-9-2", "answer-9-3"],
    isAnswered: false,
    currentAnswer: undefined,
  },
  {
    id: 10,
    title: "Em que estágio se encontra o seu projeto?",
    answers: [
      "Apenas ideia",
      "Já tenho o projeto",
      "Em desenvolvimento",
      "App pronto",
    ],
    prices: [0, 0, 0, 0],
    images: ["answer-10-1", "answer-10-2", "answer-10-3", "answer-10-4"],
    isAnswered: false,
    currentAnswer: undefined,
  },
];

import { useState, useRef, useEffect } from "react";
import "./App.css";

import Home from "./components/Home";
import Question from "./components/Question";
import Results from "./components/Result";
import Contact from "./components/Contact";

import { questions as _questions } from "./questions";

import { fadeInElement, fadeOutElement, currencyFormatter } from "./utils.js";

const setRootHeight = () =>
  (document.getElementById("root").style.height = window.innerHeight + "px");
(() => {
  window.addEventListener("resize", setRootHeight);
  document.getElementById("root").style.height = window.innerHeight + "px";
})();

const App = () => {
  const currentQuestion = useRef(0);
  const homeSection = useRef(null);
  const resultsSection = useRef(null);
  const areQuestionsImagesLoaded = useRef(false);
  const questionsContainers = useRef([]);
  const timeLastAnswerQuestion = useRef(new Date());
  const jumpToResult = useRef(false);

  const [questions, setQuestions] = useState(_questions);
  const [userAnswersPrices, setQuestionsPrices] = useState([]);
  const [renderContactContainer, setRenderContactContainer] = useState(false);

  const QUESTION_CHANGING_TRANSITION_DELAY = 1200;

  const QUESTION_FADE_IN_OUT_DELAY = 200;
  const goToQuestion = (questionId, answerId, _jumpToResult) => {
    currentQuestion.current = questionId;

    let questionToBeChanged = currentQuestion.current - 1;
    if (_jumpToResult) {
      jumpToResult.current = _jumpToResult;
      questionToBeChanged = questionId;
      fadeOutElement(resultsSection.current);
    }

    if (currentQuestion.current === 1 && !_jumpToResult)
      fadeOutElement(homeSection.current);
    else if (
      currentQuestion.current > 1 &&
      currentQuestion.current <= questions.length
    ) {
      fadeOutElement(questionsContainers.current[currentQuestion.current - 2]);
    }
    setTimeout(() => {
      if (currentQuestion.current > 1 && answerId)
        updateCurrentPrice(answerId, questionToBeChanged);
      fadeInElement(questionsContainers.current[currentQuestion.current - 1]);
    }, QUESTION_FADE_IN_OUT_DELAY);
  };

  const previousQuestion = () => {
    if (currentQuestion.current - 1 < 0) return;

    currentQuestion.current -= 1;
    fadeOutElement(questionsContainers.current[currentQuestion.current]);
    setTimeout(() => {
      fadeInElement(questionsContainers.current[currentQuestion.current - 1]);
    }, QUESTION_FADE_IN_OUT_DELAY);
  };

  const updateQuestionAnswer = (answerId, questionId) => {
    let aux = [...questions];
    aux[questionId - 1].currentAnswer = Number(answerId - 1);
    setQuestions(aux);
  };

  const setFinalPriceToAllQuestions = (totalPrice) => {
    let allQuestionsSpanPrice = document.querySelectorAll(
      `.question > .header > span.app_price`
    );
    let formattedPrice = currencyFormatter.format(totalPrice);
    [...allQuestionsSpanPrice].forEach(
      (spanPrice) => (spanPrice.innerHTML = formattedPrice)
    );
  };

  const updateQuestionsVisualPrices = (auxCurrentPrice) => {
    let formattedPrice;
    let totalPrice = auxCurrentPrice.reduce((a, b, i) => {
      let headerTotalPrice;
      if (i + 2 > questions.length) return a + b;

      if (auxCurrentPrice.length < questions.length) {
        headerTotalPrice = document.querySelector(
          `#question_${i + 2} > .header > span.app_price`
        );
        if (i === 0) formattedPrice = currencyFormatter.format(b);
        else formattedPrice = currencyFormatter.format(a + b);

        headerTotalPrice.innerHTML = formattedPrice;
      }
      return a + b;
    }, 0);
    if (auxCurrentPrice.length === questions.length)
      setFinalPriceToAllQuestions(totalPrice);

    setQuestionsPrices(auxCurrentPrice);
  };

  const updateCurrentPrice = async (answerId, questionToUpdatePrice) => {
    let auxCurrentPrice = [...userAnswersPrices];
    let answerPrice = questions[questionToUpdatePrice - 1].prices[answerId - 1];
    auxCurrentPrice[questionToUpdatePrice - 1] = answerPrice;

    updateQuestionsVisualPrices(auxCurrentPrice);
  };

  const showResults = (answerId) => {
    if (jumpToResult.current) {
      jumpToResult.current = false;
    }
    fadeOutElement(questionsContainers.current[currentQuestion.current - 1]);
    setTimeout(() => {
      updateCurrentPrice(answerId, currentQuestion.current);
      fadeInElement(resultsSection.current);
    }, QUESTION_FADE_IN_OUT_DELAY);
  };

  const ANSWER_SELECTION_DELAY = 1000;
  const answerQuestion = (e) => {
    if (
      Date.parse(new Date()) - Date.parse(timeLastAnswerQuestion.current) <
      QUESTION_CHANGING_TRANSITION_DELAY
    )
      return;
    else timeLastAnswerQuestion.current = new Date();

    const targetClass = e.target.getAttribute("class");
    const questionAnswers = [...document.getElementsByClassName(targetClass)];
    questionAnswers.forEach((answer) =>
      answer.classList.remove("current_answer")
    );
    e.target.classList.add("current_answer");

    const answerId = e.target.getAttribute("answer-id");
    const questionId = e.target.getAttribute("question-id");

    updateQuestionAnswer(answerId, questionId);
    if (
      currentQuestion.current + 1 > questions.length ||
      jumpToResult.current
    ) {
      setTimeout(() => showResults(answerId), ANSWER_SELECTION_DELAY);
    } else {
      let nextQuestion = currentQuestion.current + 1;
      setTimeout(
        () => goToQuestion(nextQuestion, answerId),
        ANSWER_SELECTION_DELAY
      );
    }
  };

  const restoreAppInitialState = async () => {
    const questionAnswers = [...document.getElementsByClassName("answer")];
    questionAnswers.forEach((answer) =>
      answer.classList.remove("current_answer")
    );

    let questionsHeaderTotalPrice = [
      ...document.querySelectorAll(`.question > .header > span.app_price`),
    ];
    questionsHeaderTotalPrice.forEach(
      (spanPrice) => (spanPrice.innerHTML = "")
    );

    setQuestionsPrices([]);
  };

  const loadQuestionsImages = () => {
    areQuestionsImagesLoaded.current = true;
    let aux = [...questions];
    aux.forEach((question) => {
      question.images.forEach(
        (img, i) =>
          (question.images[i] = require(`./images/answers/${img}.png`))
      );
    });
    setQuestions(aux);
  };

  const goHome = () => {
    fadeOutElement(resultsSection.current);
    restoreAppInitialState();
    fadeInElement(homeSection.current);
  };

  if (!areQuestionsImagesLoaded.current) loadQuestionsImages();

  useEffect(() => {
    questionsContainers.current = [
      ...document.getElementsByClassName("question"),
    ];
  }, []);

  return (
    <>
      <Home
        _ref={homeSection}
        nextQuestion={() => goToQuestion(1)}
      />

      {questions.map((question, i) => {
        return (
          <Question
            key={i}
            id={question.id}
            title={question.title}
            answers={question.answers}
            images={question.images}
            answerQuestion={answerQuestion}
            previousQuestion={previousQuestion}
            totalQuestion={questions.length}
          />
        );
      })}
      <Results
        resultsSection={resultsSection}
        questions={questions}
        userAnswersPrices={userAnswersPrices}
        goToQuestion={goToQuestion}
        goHome={goHome}
        setRenderContactContainer={setRenderContactContainer}
      />

      <Contact
        renderContactContainer={renderContactContainer}
        setRenderContactContainer={setRenderContactContainer}
      />

      <div id="footer">
        <p>Orni - Tecnologia que gera lucro - 2023 </p>
        <p></p>
        <p>
          Fale conosco para ter algo customizado e uma estimativa mais
          assertiva.
        </p>
        {/* <p>Entre em contato com um especialista </p> */}
      </div>
    </>
  );
};

export default App;
